export default {
    seo: {
      title: 'Updates | Bank 131',
      description: 'Updates'
    },
    title: 'Updates',
    content: [
        {
            title: 'Changes in the Board of Directors',
            clarification: {
                label: '',
                text: '<b style="display: contents;">Changes in the Board of Directors</b><br />'+
                    'On May 4, 2022, D. Eremeev, S. Eremeev, and N. Eremeev were removed from the board '+
                    'of directors of Bank 131, LLC. On May 4, 2022, new members of the board of directors '+
                    'of Bank 131, LLC was elected. The authority of the previously elected members '+
                    'of the board (D. Eremeev, S. Eremeev, and N. Eremeev) has been terminated.'
            }
        }
    ]
}
