import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";
import Clarification from "../../components/Clarification";

import enMessages from '../../i18n/legal/importantUpdates/en.js'
import ruMessages from '../../i18n/legal/importantUpdates/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const ImportantUpdatesPage = ({ pathContext: { locale } }) => {
    const section = 'importantUpdates';

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >

        {messages[locale].content.map((item, index) => {
            return <React.Fragment key={`update_${item.link}${index}`}>
                {item.link && (
                    <RectangleLink
                        title={item.title}
                        label={item.label}
                        link={item.link}
                        target="_blank"
                    />
                )}

                {item.clarification && (
                    <Clarification
                        label={item.clarification.label}
                        text={item.clarification.text}
                    />
                )}
            </React.Fragment>
        })}

        </TemplateLegalPage>
    )
};

export default ImportantUpdatesPage;
