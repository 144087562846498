import audit_2019 from '../../../assets/files/ru/Audit_2019.pdf';

export default {
    seo: {
      title: 'Важная информация | Банк 131',
      description: 'Важная информация'
    },
    title: 'Важная информация',
    content: [
        {
            label: 'Дата размещения 16.07.2024',
            title: 'Сообщение о принятом решении о реорганизации',
            link: '/legal/ru/notice_of_the_decision_on_reorganization.pdf',
            clarification: {
                label: '',
                text: '<b style="display: contents;">Изменения в составе совета директоров</b><br />'+
                        '04.05.2022 г. прекращены полномочия следующих членов совета директоров АО «Банк 131»: '+
                        'Еремеева Д.Н., Еремеева С.Н., Еремеева Н.О. 04.05.2022 г. избран новый состав членов совета '+
                        'директоров ООО «Банк 131». Полномочия ранее избранного состава членов совета директоров '+
                        '(Еремеева Д.Н., Еремеева С.Н., Еремеева Н.О.) прекращены.'
            }
        },
    ],
}